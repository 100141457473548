<template>
  <div>
  <base-dialog
    :title="editTitle"
    :visible.sync="dialogVisible"
    width="650px"
    @cancel="cancel"
    @confirm="confirm"
  >
  <el-row style="margin-bottom:10px">
    <el-col :span="5">{{ $t('serverManage.openCompany') }}</el-col>
    <el-col :span="10">
      <template v-if="editTrue">
      {{editRow.companyName}}
      </template>
      <template v-else>
        <el-input size="mini" v-model="companyName" @click.native="openDialog"></el-input>
      </template>
    </el-col>
  </el-row>
  <el-table
      :data="gridData"
      row-key="type"
      lazy
      :load="loadChildren"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
  >
    <el-table-column prop="type" :label="this.$t('serverManage.productModule')" width="150" v-slot:default="{ row }">
      {{ row.type === 'NORMAL'? $t('businessEnum.NORMAL1') :$t('businessEnum.' + row.type) }}
    </el-table-column>
    <el-table-column prop="isGrant" :label="this.$t('serverManage.openingStatus')" width="200">
      <template slot-scope="scope">
      <el-switch
        v-model="scope.row.isGrant"
        :active-value="1"
        :inactive-value="0"
        :width="75"
        class="main-el-switch"
        :active-text="$t('serverManage.opened')"
        :inactive-text="$t('serverManage.closed')"
        @change="changeSwitchValue(scope.row)"
        >
      </el-switch>
     </template>
    </el-table-column>
    <el-table-column prop="feeData" :label="$t('serverManage.billingMode')">
       <template slot-scope="scope">
        <el-select v-model="scope.row.feeData" clearable :placeholder="$t('serverManage.pleaseSelect')" filterable @change="changeValue(scope.row)">
        <el-option v-for="item in scope.row.fee"
          :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </template>
    </el-table-column>
  </el-table>

  </base-dialog>
  <!-- 弹出表格查 企业 -->
  <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
  </dialog-table-list>
  </div>
</template>
<script>

import {findCompanyProductVersion, findProductVersionType, updateCompanyProductVersion} from "@/api";

export default {
  props: {
    // 当前选中的表格行数据
    editRow: {
      type: Object,
      default: () => null,
    },
    editTrue:{
      type: Boolean,
      default: false
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    return {
      dialogVisible: false,
      cmd,
      formData: {},
      gridData:[],
      productConfigs:[],
      selectCurrent:[],
      docModel:0,
      companyName:'',
      companyId:'',
      feeID:'', // 计费模式ID
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
      editTitle: this.editTrue ? this.$t('serverManage.modifyProduct') : this.$t('serverManage.openProduct')
    }
  },
  mounted() {
    this.productTypeFind()
  },
  methods: {
    loadChildren(tree, treeNode, resolve) {
      this.$ajax({
        url: this.$api.findProductVersionType,
      }).then((data) => {
        if(this.editRow) {
          this.$ajax({
            url: this.$api.findCompanyProductVersion,
            data: {
              companyUid: this.editRow.companyUid
            }
          }).then(res => {
            data = data.map(item => {
              let isGrant = 0
              const res1 = res.filter(item1 => item1.versionType === item.value)
              if (res1.length > 0) {
                isGrant = res1[0].isGrant === 'YES' ? 1 : 0
              }
              return {
                type: item.value,
                fee: this.$dict.resourceFeeModeEnum(true),
                feeData: '',
                isGrant,
              }
            })
            resolve(data)
          })
        } else {
          data = data.map(item => {
            let isGrant = 0
            // const res1 = res.filter(item1 => item1.versionType === item.value)
            // if (res1.length > 0) {
            //   isGrant = res1[0].isGrant === 'YES' ? 1 : 0
            // }
            return {
              type: item.value,
              fee: this.$dict.resourceFeeModeEnum(true),
              feeData: '',
              isGrant,
            }
          })
          resolve(data)
        }
      })
    },
     // 设置默认值
    productTypeFind() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findProductTypeList,
      }).then((data) => {
        data = data.filter(item => item.value !== 'CORE')
        this.gridData = data.map(item => {
            if (item.value === 'XR_WORK') {
              return {
                type: item.value,
                fee: this.$dict.resourceFeeModeEnum(true),
                feeData: '',
                isGrant: 0,
                hasChildren: true,
              }
            } else {
              return {
                type: item.value,
                fee: this.$dict.resourceFeeModeEnum(true),
                feeData: '',
                isGrant: 0
              }
            }
          }
        )
        console.log(this.gridData)
      })

      if(this.editTrue){
        this.companyId = this.editRow.companyId
        setTimeout(() =>{
          const { productConfigs } = this.editRow
          for (let k = 0; k < this.gridData.length; k++) {
            productConfigs.forEach(item => {
              if(item.type === this.gridData[k].type) {
                this.gridData[k].isGrant = item.isGrant === 'YES' ? 1 : 0
                this.gridData[k].feeData = item.feeMode
                const newarr = {
                  feeMode: item.feeMode,
                  feeModeStr: item.feeModeStr,
                  isGrant: item.isGrant,
                  type: item.type,
                  id: item.id
                }
                this.productConfigs.push(newarr)
              }
            })
          }
        },500)
        this.formData = {
          companyId:this.companyId,
          productConfigs: this.productConfigs
        }
      }
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.companyName = row.name
      this.companyId = row.id
      this.showDialogTableList = false
      this.formData = {
        companyId: this.companyId,
        productConfigs:this.productConfigs
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findCompanyConfig,
        data: {
          id: row.id
        },
      }).then((data) => {
        console.log(data)
        // console.log(this.gridData)
        // 初始化
        this.productConfigs = []
        for (let k = 0; k < this.gridData.length; k++) {
          this.gridData[k].isGrant = 0
          this.gridData[k].feeData = ''
        }

        if(data) {
          const dataNew = this.$util.copyData(data.productConfigs)
          this.orderID = dataNew.id
          console.log(this.gridData)
          for (let k = 0; k < this.gridData.length; k++) {
            dataNew.forEach(item => {
              if(item.type === this.gridData[k].type) {
                this.gridData[k].isGrant = item.isGrant === 'YES' ? 1 : 0
                this.gridData[k].feeData = item.feeMode
                const newarr = {
                  feeMode: item.feeMode,
                  feeModeStr: item.feeModeStr,
                  isGrant: item.isGrant,
                  type: item.type,
                  id: item.id
                }
                this.productConfigs.push(newarr)
              }
            })
          }
        }
        this.formData = {
          companyId:this.companyId,
          productConfigs: this.productConfigs
        }
        console.log(this.formData)
      })
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('adminAccount.companyInfo')
      this.dialogUrl = this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    changeValue(e) {
      this.selectCurrent = e
      // console.log(this.selectCurrent)
      // 查询计费模式value，label 值
      const labelCheck = this.selectCurrent.fee.find(item => {
        return item.value === this.selectCurrent.feeData
      })
      // 查找
      const index = this.productConfigs.findIndex(item => {
        return item.type === e.type
      })
      let isGrantStr = 'NO'
      if(this.selectCurrent.isGrant === 1) {
        isGrantStr = 'YES'
      }
      const newE = {
        feeMode: labelCheck.value ? labelCheck.value : null,
        feeModeStr: labelCheck.label ? labelCheck.label : null,
        isGrant: isGrantStr,
        type: this.selectCurrent.type,
      }
      const result = this.productConfigs.some(item => item.type === this.selectCurrent.type)
       if (result) { // 存在 根据type修改对应的值
        const index = this.productConfigs.findIndex(
          (item) => item.type === this.selectCurrent.type
        )
        this.$set(this.productConfigs, index, {
          ...this.productConfigs[index],
           feeMode: labelCheck.value ? labelCheck.value : null,
           feeModeStr: labelCheck.label ? labelCheck.label : null,
        })
       }else {
         this.productConfigs.push(newE)
       }
      this.formData = {
        companyId:this.companyId,
        productConfigs:this.productConfigs
      }
      // console.log(this.formData)
    },
    changeSwitchValue(e) {
      console.log(e)
      // if(e.type === 'XR_WORK' && e.isGrant === 1) {
      //   this.loadChildren()
      // }
      this.selectCurrent = e
      // 查询计费模式value，label 值
      let labelCheckStr = null
      let valueCheckStr = null
      // 读取计费模式Label
      if(this.selectCurrent.feeData){
        const labelCheck = this.selectCurrent.fee.find(item => {
          return item.value === this.selectCurrent.feeData
        })
        valueCheckStr = labelCheck.value
        labelCheckStr = labelCheck.label
      }
      const index = this.productConfigs.findIndex(item => {
        return item.type === e.type
      })
      let isGrantStr = 'NO'
      if(this.selectCurrent.isGrant === 1) {
        isGrantStr = 'YES'
      }
      const newE = {
        feeMode: labelCheckStr,
        feeModeStr: valueCheckStr,
        isGrant: isGrantStr,
        type: this.selectCurrent.type,
      }
      const result = this.productConfigs.some(item => item.type === this.selectCurrent.type)
       if (result) { // 存在 根据type修改对应的值
        const index = this.productConfigs.findIndex(
          (item) => item.type === this.selectCurrent.type
        )

        this.$set(this.productConfigs, index, {
          ...this.productConfigs[index],
          isGrant: isGrantStr
        })
       }else {
         this.productConfigs.push(newE)
       }
      this.formData = {
        companyId: this.companyId,
        productConfigs:this.productConfigs
      }
      // console.log(this.formData)
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      console.log(this.cmd)
      const productTypes = [
          'TMVA',
          'XR_WORK',
          'TMVA_IDC',
      ]
      const productConfigs = formData.productConfigs.filter(item => productTypes.indexOf(item.type) > -1)
      const xrProductConfigs = formData.productConfigs.filter(item => productTypes.indexOf(item.type) === -1)
      formData.productConfigs = productConfigs
      console.log('formData', xrProductConfigs)
      this.addMethod(formData)
      const xrWorkConfig = productConfigs.filter(item => item.type === 'XR_WORK')
      console.log('xrWorkConfig', xrWorkConfig)
      if(xrWorkConfig.length > 0) {
        if(xrWorkConfig[0].isGrant === 'NO') {
          xrProductConfigs.map(item => {
            item.isGrant = 'NO'
            return item
          })
        }
      }
      xrProductConfigs.map(item => {
        item.versionType = item.type
        delete item.type
        delete item.feeMode
        delete item.feeModeStr
        item.companyUid = this.editRow.companyUid
        return item
      })
      const xrFormData = {
        companyUid: this.editRow.companyUid,
        productVersionEntities: xrProductConfigs
      }
      console.log('xrFormData', xrFormData)
      this.$ajax({
        url: this.$api.updateCompanyProductVersion,
        data: xrFormData,
      }).then(() => {})
      // switch (this.cmd) {
      //   case 'add':
      //     this.addMethod(formData)
      //     break
      //   default: // do something
      // }
    },
    // 新增
    addMethod(formData) {
      if(!formData.companyId) {
        this.$element.showMsg(this.$t('serverManage.selectOpenProduct'), 'warning')
        return false
      }
      if(!formData.productConfigs.length){
       this.$element.showMsg(this.$t('serverManage.selectProductModel'), 'warning')
       return false
      }
      if(formData.productConfigs.length) {
        for (let i = 0; i < formData.productConfigs.length; i++) {
          if(formData.productConfigs[i].isGrant === 'YES' ) {
            if(formData.productConfigs[i].feeMode === null) {
              this.$element.showMsg(this.$t('serverManage.selectBillingMode'), 'warning')
              return false
            }
          }
          if(formData.productConfigs[i].feeMode === 'METERING' ) {
            formData.productConfigs[i].feeModeStr = 'METERING'
          }
          if(formData.productConfigs[i].feeMode === 'ACCOUNT_SUBSCRIPTIONS' ) {
            formData.productConfigs[i].feeModeStr = 'ACCOUNT_SUBSCRIPTIONS'
          }
        }
      }
       //formData.feeModeStr = formData.feeMode
       console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.updateProductService,
        data: formData,
      }).then(() => {
        if(this.editTrue) {
          this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        } else{
          this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        }
        this.$emit('closePage', 'add')
      })
    },

  },
}
</script>
<style lang="scss" scoped>
  ::v-deep .clearPadding .vxe-cell {
    padding: 0;
  }
  ::v-deep .clearPaddingLeft .vxe-cell {
    padding-left: 0px;
  }
  ::v-deep {
    .main-el-switch {
      position: relative;
        .el-switch__core {
          height: 24px;
          border-radius: 12px;
          &:after {
            left: 4px;
            top: 3px;
          }
        }
        &.el-switch {
          &.is-checked {
          .el-switch__core {
            &:after {
              margin-left: -20px;
              left: 100%;
            }
          }
        }
      }
      &.is-checked {
        .el-switch__label--left {
          opacity: 0;
        }
        .el-switch__label--right {
          opacity: 1;
        }
      }
      .el-switch__label {
        position: absolute;
        top: 0;
      }
      .el-switch__label--left {
        right: 0;
        color: #999;
        z-index: 1;
        margin-right: 8px;
      }
      .el-switch__label--right {
        left: 0;
        color: #fff;
        opacity: 0;
        margin-left: 8px;
      }
    }
  }
</style>
